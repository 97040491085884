<template>
  <v-card :loading="loading">
    <v-system-bar>Q-Arbeiten</v-system-bar>
    <v-data-table :headers="headers" :items="itemsFiltered" sort-by="person">
      <template v-slot:item.person="{ item }">
        <PersonItemBasic :value="item.person" />
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { defineComponent } from "vue";
import { comparePeople, searchPerson } from "common/utils/people";
import PersonItemBasic from "common/components/PersonItemBasic.vue";
export default defineComponent({
  components: { PersonItemBasic },
  props: ["search", "period"],
  data() {
    return {
      headers: [
        {
          text: "LK",
          value: "person",
          sort: comparePeople,
        },
        {
          text: "Instrument",
          value: "instrument.description",
          sortable: false,
        },
        { text: "Beschreibung", value: "description", sortable: false },
      ],
      items: [],
      loading: false,
    };
  },
  computed: {
    itemsFiltered() {
      return this.items.filter(
        (item) => !this.search || searchPerson(item.person, this.search)
      );
    },
  },
  watch: {
    period() {
      this.fetchData();
    },
  },
  methods: {
    async fetchData() {
      this.loading = true;
      this.items = await this.apiList({
        resource: "quality/activity",
        query: this.period ? "period=" + this.period.id : "",
      });
      this.loading = false;
    },
  },
  async created() {
    this.fetchData();
  },
});
</script>
